.teacher-demo-container {
    /* height: 85vh; */
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    margin-inline: auto;
    padding: 10vh 0;
}

.code-box-teacher {
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    border: 2px solid #626262;
    border-radius: 10px;
    height: 40em;
}

.topics-box {
    width: 30%;
    /* border-left: 1px solid blue; */
    background-color: #24292f;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.topic {
    height: 60px;
    width: 100%;
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.classroom-box {
    /* border: 1px solid yellow; */
    width: 70%;
    height: 100%;
    background-color: #15181b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.box-header {
    margin: 20px 0 70px 0;
}

.student-details {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    padding: 0 50px;
    /* height: 300px; */
}

.student-details>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
}

.single-detail-box {
    /* padding-top: 40px;
    height: 60px; */
    /* border: 1px solid blue; */
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.line {
    height: 2px;
    margin-top: 5px;
    width: 1.5em;
    background-color: #4c515c;
}

.view-code-btn {
    font-size: 1rem;
    padding: 0 10px;
    background-color: #090b0c;
    border-radius: 10px;
    border: 1px solid rgba(172, 172, 172, 0.2);
}


/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {
    .teacher-demo-container {
        display: flex;
        flex-direction: column;
        /* height: 120vh; */
        /* border: 1px solid red; */
        /* height: 80vh; */

    }

    .code-box-teacher {
        /* height: 100%; */
        /* border: 1px solid blue; */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px),
(max-width: 576px) {
    .teacher-demo-container {
        /* margin-top: 50vh; */
        width: 100%;
        padding: 0;
    }

    .topics-box {
        /* width: 27%; */
    }

    .topic {
        padding: 10px;
    }

    .topics-box>div:first-child {
        font-size: 1.2rem;

    }

    .classroom-box {
        /* width: 73%; */
    }

    .topics-box>div {
        font-size: 0.85rem;
    }

    .single-detail-box {
        font-size: 0.85rem;
    }

    .student-details>div:nth-child(4) {
        justify-content: space-between;
    }

    .student-details {
        padding: 0 10px;
    }
}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {
    .teacher-demo-container {
        /* height: 95vh; */
    }

    .single-detail-box {
        font-size: 0.8rem;
    }
}

@media (max-width: 500px) {
    .topics-box {
        width: 31%;
    }
}