.very-small-text {
    font-size: 0.875rem;
    color: white;
}

.small-text {
    font-size: 1rem;
    color: white;
}

.medium-small-text {
    font-size: 1.25rem;
    color: white;

}

.medium-text {
    font-size: 1.5rem;
    color: white;
}

.large-text {
    font-size: 2.375rem;
    color: white;
}

.very-large-text {
    font-size: 3rem;
    color: white;
}

.extra-large-text {
    font-size: 3.6rem;
    color: white;
}

.extra-extra-large-text {
    font-size: 4.5rem;
    color: white;
}


.light-bold {
    font-weight: 500;
}

.extra-bold {
    font-weight: 700;
}

.max-bold {
    font-weight: 900;
}

.very-small-icon {
    font-size: 1.2rem;
}

.small-icon {
    font-size: 1.5rem;
}

.medium-icon {
    font-size: 2.5rem;
}

.large-icon {
    font-size: 3rem;
}

.highlight-text {
    background: -webkit-linear-gradient(bottom right, #4CBFED, #4CEDA0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.highlight-icon {
    color: #4CEDA0;
}




/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1300px) {
    .very-small-text {
        font-size: 0.875rem;
        color: #a0abc4;
    }

    .medium-small-text {
        font-size: 1.1rem;
        color: #a1acc5;
    }

    .medium-text {
        font-size: 1.5rem;
        color: white;
    }

    .large-text {
        font-size: 2.5rem;
        color: white;
    }

    .very-large-text {
        font-size: 2.8rem;
        color: white;
    }

    .extra-extra-large-text {
        font-size: 4rem;
    }
}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {
    .very-small-text {
        font-size: 0.875rem;
        color: #a0abc4;
    }

    .medium-small-text {
        font-size: 1.1rem;
        color: #a1acc5;
    }

    .medium-text {
        font-size: 1.3rem;
        color: white;
    }

    .large-text {
        font-size: 1.8rem;
        color: white;
    }

    .very-large-text {
        font-size: 2.8rem;
        color: white;
    }

    .extra-large-text {
        font-size: 3rem;
        color: white;
    }

    .extra-extra-large-text {
        font-size: 4rem;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px),
(max-width: 768px) {
    .very-small-text {
        font-size: 0.875rem;
        color: #a0abc4;
    }

    .small-text {
        font-size: 0.95rem;
        color: #a1acc5;
    }

    .medium-small-text {
        font-size: 1rem;
        color: #a1acc5;
    }

    .medium-text {
        font-size: 1.2rem;
        color: white;
    }

    .large-text {
        font-size: 1.8rem;
        color: white;
    }

    .very-large-text {
        font-size: 1.8rem;
        color: white;
    }

    .extra-large-text {
        font-size: 2.1rem;
        color: white;
    }

    .extra-extra-large-text {
        font-size: 3.2rem;
    }

    .very-small-icon {
        font-size: 0.875rem;
    }

    .small-icon {
        font-size: 1rem;
    }

    .medium-icon {
        font-size: 1.75rem;
    }

    .large-icon {
        font-size: 2rem;
    }
}