.top-part-container {
    height: 93vh;
    position: relative;
    z-index: 9999;
    display: flex;
    width: 80%;
    /* border: 1px solid blue; */
    margin-inline: auto;
    /* justify-content: center; */
    /* align-items: center; */
    /* border: 1px solid none;   */
    /* padding-top: 10%; */
    /* flex-wrap: wrap; */
    align-items: center;
}

.top-part-container>div:first-child {
    width: 45%;
    /* border: 1px solid red; */
    padding-right: 20px;
    height: fit-content;
    /* height: fit-content;
    max-height: fit-content; */

}

.top-part-container>div:first-child>p:nth-child(2) {
    line-height: 35px;
}

.top-part-container>div:nth-child(2) {
    width: 55%;
    position: relative;
    height: fit-content;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(76, 191, 237), rgb(76, 237, 160)) 1;
}


.play-video-box {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #4cc0ed;
    /* transition: 0.3s linear; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.5);
    transition: 0.2s linear;
}

.play-video-icon {
    color: white;
    cursor: pointer;
    transition: 0.2s linear;
}

.play-video-icon:hover {
    transform: scale(1.15);
}

.video {
    width: 100%;
    height: 100%;
}

.top-part-container>div>p:nth-child(2) {
    margin-top: 20px;
}

/* .top-part-container::before {
    content: "";
    background-image: url(../../../images//11.png);
    background-size: contain;
    z-index: -999;
    opacity: 1;
    position: absolute;
    background-repeat: no-repeat;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
} */

/* .top-part-container > div {
    width: fit-content;
    letter-spacing: 2px;
    text-align: center;
    margin: auto;
} */

.top-part-container>div>p:nth-child(2) {
    letter-spacing: normal;
}

.free-trial-btn {
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(76, 191, 237), rgb(76, 237, 160)) 1;
    height: 70px;
    width: 200px;
    display: flex;
    margin: auto;
    margin-top: 80px;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    letter-spacing: 0.3px;
    transition: 0.3s ease;
}

.free-trial-btn:hover {
    box-shadow: 4px 4px 160px rgba(76, 191, 237, 0.5), -4px -4px 160px rgba(76, 237, 160, 0.5);
    border-image: linear-gradient(to right, rgb(76, 237, 160), rgb(76, 191, 237)) 1;
}

.right-arrow {
    margin-left: 10px;
}

.text-shadow {
    /* text-shadow: 2px 2px 4px rgba(76, 191, 237, 0.5); */
}


/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1350px) {
    .top-part-container {
        flex-direction: column;
        /* border: 1px solid red; */
        width: 90%;
        padding-top: 10%;
    }

    .top-part-container>div:first-child {
        /* padding-right: 50px; */
        padding: 0;
        width: fit-content;
        text-align: center;
        /* margin-inline: auto; */
        /* border: 1px solid blue; */
    }

    .top-part-container>div:nth-child(2) {
        /* padding-right: 50px; */
        width: 80%;
        margin-inline: auto;
        margin-top: 30px;
    }

}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {
    .top-part-container {
        padding-top: 20%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px),
(max-width: 576) {
    .free-trial-btn {
        height: 70px;
        width: 210px;
    }

    .top-part-container {
        padding-top: 35%;
    }
}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {}