.navbar-burger-container {
    position: absolute;
    top: 100px;
    /* border: 1px solid red; */
    right: 0;
    top: 70%;
    /* width: 200px; */
    padding: 10px 50px 10px 20px;
    background-color: #383a4e;
    box-shadow: 5px 10px 20px rgba(19, 20, 27, 0.9);
    border-radius: 10px;
    display: none;
}

.navbar-burger-container>p {
    margin: 10px 0;
}

.navbar-burger-container>div>p {
    margin: 8px 0;
}

.navbar-line {
    height: 2px;
    background-color: rgba(19, 20, 27, 0.3);
    width: 100%;
    margin: 15px 0;
}


/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px),
(max-width: 576px) {
    .navbar-burger-container {
        top: 20px;
        z-index: 99999999;
        display: block;
    }
}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {}