.navbar-container {
    /* height: 7vh; */
    width: 100%;
    /* border: 1px solid red; */
    /* background-color: rgb(187, 208, 255); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s linear;
    padding-top: 3vh;
}

.logo-box {
    margin-left: 20px;
}

.logo {
    height: 32px;
    /* object-fit: cover; */
    cursor: pointer;
}

.link-box {
    display: flex;
    margin-right: 30px;
    width: auto;
    /* border: 1px solid yellow; */
    justify-content: space-between;
    position: relative;
    transition: 0.3s ease;

}

.link-box>div {
    cursor: pointer;
    margin-left: 50px;
}

.nav-link {
    /* border: 1px solid blue; */
    height: 7vh;
    display: flex;
    align-items: center;
}

.nav-link:hover {
    background: -webkit-linear-gradient(#4CBFED, #4CEDA0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navbar-container-fixed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* transition: 0.1s ease; */
    /* padding: 0; */
    position: sticky;
    width: 100%;
    top: 0;
    /* border: 1px solid red; */
    background-color: rgb(29, 30, 41);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
    z-index: 999999;
    transition: 0.3s linear;
    padding: 1vh 0;
}

.solutions-box,
.resource-box,
.company-box {
    width: 180px;
    /* border: 1px solid rgba(255, 255, 255, 0.1); */
    /* height: 80px; */
    /* border: 1px solid red; */
    position: absolute;
    top: 7vh;
    left: -60px;
    z-index: 9999999;
    background-color: #2a2d3b;
    border-radius: 10px;
    box-shadow: 5px 10px 10px rgba(13, 17, 27, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

.resource-box {
    left: 67px;
}

.company-box {
    left: 160px;
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 40px; */
    width: 100%;
    padding: 15px 0;
    /* margin: 10px 0; */
    /* border: 1px solid red; */
    border-radius: 10px;
    transition: 0.2s ease;
    color: white;
}

.link:hover {
    background-image: linear-gradient(rgba(76, 191, 237, 0.3), rgba(76, 237, 160, 0.3));
}

.navbar-burger-box {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    transition: 0.3s linear;
    display: none;
}


/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {
    .logo {
        height: 28px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px),
(max-width: 576px) {
    .navbar-container {

        width: 100%;
        /* border: 1px solid red; */
        /* background-color: rgb(187, 208, 255); */
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s ease;
        padding-top: 3vh;
    }

    .navbar-container-fixed {
        padding: 2vh 0;
    }

    .navbar-burger-box {
        display: block;
    }

    .nav-link {
        display: none;
    }

    .logo {
        height: 24px;
    }
}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {}