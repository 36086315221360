.folder-box {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #161b22;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    padding-left: 30px;
}

.code-box-teacher {
    display: flex;
    /* flex-direction: column; */
}

.code-box {
    height: 100%;
}

.failed-box-teacher {
    border: 1px solid #ff6363;
    position: absolute;
    width: 230px;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
}

.fix-code-line {
    border: 1px solid #63666b;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #24292f;
}

.failed-box-teacher>div {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.edit-post-box {
    display: flex;
    /* border: 1px solid red; */
    width: 330px;
    margin: auto;
    margin-top: 10px;
}

.edit-post-box>div {
    width: 140px;
    padding: 10px 0;
}

.post-hint-btn:hover {
    box-shadow: 2px 2px 60px rgba(35, 255, 101, 0.5);
    color: rgba(35, 255, 101);
}

.apply-fix-box-clicked {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161b22;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    padding: 15px 20px;
    border-radius: 10px;
    /* color: #33daff; */
    cursor: pointer;
    transition: 0.3s ease;
    box-shadow: 3px 3px 20px rgba(51, 218, 255, 0.9), -3px -3px 20px rgba(51, 218, 255, 0.9);
    border: 2px solid rgb(51, 218, 255, 0.9);
    color: #6be4ff;
}

.apply-fix-box-clicked>p {
    margin-left: 10px;
}

.teacher-edit-hint-box {
    width: 480px;
    left: 400px;
    line-height: 30px;
    top: 70px;
}

.post-hint-btn-clicked {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161b22;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    padding: 15px 20px;
    border-radius: 10px;
    /* color: #33daff; */
    cursor: pointer;
    transition: 0.3s ease;
    box-shadow: 3px 3px 20px rgba(35, 255, 101, 0.9), -3px -3px 20px rgba(35, 255, 101, 0.9);
    border: 2px solid rgb(35, 255, 101, 0.9);
    color: rgba(35, 255, 101);
}




/* Media Queries */

@media (max-width: 1300px) {
    .teacher-edit-hint-box {
        left: 310px;
        line-height: 25px;
    }
}

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {
    .teacher-edit-hint-box {
        line-height: 20px;
        top: -40px;
        left: 30px;
        min-height: 315px;
    }

    .teacher-edit-hint-box>div:nth-child(2) {
        padding: 5px;
    }

    .teacher-edit-hint-box p,
    .teacher-edit-hint-box>div:nth-child(2)>div {
        font-size: 0.7rem;
        margin: 0;
    }

    .teacher-line-pointer {
        left: 15px;
        top: 260px;
    }

    .teacher-hint-line-icon {
        top: 285px;
    }

    .edit-post-box {
        margin-top: 0;
    }

    .teacher-line-numbers {
        margin-bottom: 11.5px;
    }

    .teacher-hint-line {
        top: 285px;
    }
}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px),
(max-width: 576px) {
    .teacher-line-numbers {
        margin-bottom: 12px;
    }

    .teacher-edit-hint-box {
        min-height: 300px;
        top: -45px;
        left: 30px;
        width: 350px;
    }

    .teacher-edit-hint-box>div:nth-child(2) {
        padding: 5px;
    }

    .teacher-edit-hint-box p,
    .teacher-edit-hint-box>div:nth-child(2)>div {
        font-size: 0.7rem;
        margin: 0;
    }

    .teacher-line-pointer {
        left: 15px;
        top: 245px;
    }

    .teacher-hint-line-icon {
        top: 270px;
    }

    .edit-post-box {
        margin-top: 0;
    }

    .teacher-hint-line {
        top: 272px;
    }
}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {
    .teacher-custom-msg-box {
        margin-left: -10px;
    }

    .teacher-edit-hint-box {
        height: 300px;
        width: 330px;
        top: -25px;
        left: 30px;
    }

    .teacher-edit-hint-box>div:nth-child(2) {
        padding: 5px;
    }

    .teacher-edit-hint-box p,
    .teacher-edit-hint-box>div:nth-child(2)>div {
        font-size: 0.7rem;
        margin: 0;
    }

    .teacher-line-pointer {
        left: 15px;
        top: 260px;
    }

    .teacher-hint-line-icon {
        top: 290px;
    }


    .teacher-line-numbers {
        margin-bottom: 17px;
        font-size: 0.8rem;
    }

    .teacher-hint-line {
        top: 290px;
        width: 300px;
    }

    .hint-msg-header {
        margin-top: -10px;
        font-size: 0.8rem;
    }

    .teacher-edit-hint-box {
        /* background-color: red; */
    }

    .teacher-custom-msg-box {
        background-color: red;
    }


    .edit-post-box {}
}

@media (max-width: 500px) {
    .teacher-edit-hint-box {
        left: 20px;
    }

    .teacher-line-pointer {
        left: 5px;
    }
}