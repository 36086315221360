.impact-container {
    /* border: 1px solid red; */
    padding: 31.25em 0;
    min-width: 100%;
    /* margin: auto; */
    position: relative;
    /* border: 1px solid red; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* margin-bottom: 50vh; */
    background-color: #0b0f16;
}

/* .impact-container::before {
    content: "";
    background-size: contain;
    opacity: 1;
    position: absolute;
    background-image: url("../../../images/lines.png");
    background-repeat: no-repeat;
    top: 5px;
    right: 10%;
    width: 120px;
    height: 300px;
    z-index: 99;
} */

.impact-box {
    /* height: 400px; */
    width: 80%;
    /* border: 1px solid blue;   */
    /* margin: auto; */
    /* margin-top: 100px; */
    /* position: relative; */
    /* background-image: linear-gradient(to bottom right, rgb(37, 87, 110),rgb(1, 34, 43)); */
    /* border-radius: 20px; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.impact-box>p {
    text-align: center;
    /* padding-top: 50px; */
    margin-bottom: 20px;
}

.stats-container {
    display: flex;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    justify-content: space-around;
    margin: auto;
    margin-top: 180px;
}

.stats-box {
    position: relative;
    height: 300px;
    width: 300px;
    border: 1px solid #58FF87;
    border-width: 7px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.number-box {
    position: absolute;
    z-index: 99;
    /* border: 1px solid red; */
    top: 3px;
    width: 120px;
    height: 25px;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0b0f16;

}

.counter {
    position: absolute;
    z-index: 999;
    top: -80px;
}


/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {
    .impact-container {
        padding: 35em 0;
    }
}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {
    .stats-box {
        height: 250px;
        width: 250px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 950px),
(max-width: 576px) {


    .impact-container {
        /* margin-top: 100vh; */
        padding: 48em 0;
        /* border: 1px solid red; */
    }

    .stats-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 150px;
    }

    .stats-box {
        margin-bottom: 120px;
    }

    .counter {
        top: -50px;
        font-size: 3.2rem;
    }
}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {
    .impact-box {
        padding-top: 200px;
    }


    .stats-container {
        margin-bottom: 200px;
    }
}