.message-component-box {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: #191c20;
    margin-top: 15px;
    border-radius: 10px;
    position: relative;
    padding: 12px 20px;
    border: 1px solid #323e4e;
}

.user-pic {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #52637c;
    margin-right: 10px;
    object-fit: cover;
}

.message-component-box>div {
    display: flex;
    width: 100%;
    align-items: center;

}

.user-name {
    margin-right: 30px;
    font-weight: 500;
    color: #93acc5;
}

.user-time {
    color: #93acc5;
    font-size: 0.9rem;
    position: absolute;
    right: 20px;

}

.user-msg {
    color: #dfe4f0;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    line-height: 25px;
}

.message-component-box>div:nth-child(2) {
    background-color: #191c20;
    /* padding: 10px 20px; */
    border-radius: 10px;
    margin-top: 10px;
}

.line10 {
    color: #FF6363;
    font-weight: 500;
}

.fix {
    color: #58ff87;
    font-weight: 500;
}

.fix-msg {
    border: 1px solid #63666b;
    padding: 2px 10px;
    font-family: 'Source Code Pro';
    background-color: #24292f;
    border-radius: 5px;
    /* margin-top: 3px; */
}



/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {
    .user-msg {
        line-height: 18px;

    }

    .user-pic {
        height: 20px;
        width: 20px;
    }
}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px),
(max-width: 576px) {
    .user-pic {
        height: 20px;
        width: 20px;
    }

    .user-msg {
        line-height: 18px;

    }

}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {
    .message-component-box {
        margin-top: 10px;
        padding: 5px;
    }
}