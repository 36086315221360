.custom-hint-box {
    display: flex;
    flex-direction: column;
}

.custom-hint-box>div:first-child {
    width: 100%;
    /* border: 1px solid red; */
    height: 480px;
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.edit-hint-box {
    width: 45%;
    /* border: 1px solid blue; */
    /* height: 1005; */
}

.edit-hint-box>p {
    margin-bottom: 20px;
}

.teacher-hint-msg-box {
    border: 1px solid rgb(146, 146, 146);
    background-color: #161b22;
    border-radius: 10px;
    padding: 30px 20px;
    margin-top: 30px;
    line-height: 30px;
}

.teacher-custom-msg-box {
    padding: 30px 20px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: #0d1014;
    /* background-color: #a0a3aa; */
    border: 1px solid rgba(183, 221, 240, 0.4);
}

.fix-msg-box {
    border: 1px solid red;
}

.save-hint-btn {
    width: 150px;
}

.teacher-student-chat-box {
    /* height: 500px; */
    background-color: #24292f;
}

.day-box {
    padding: 2px 0;
    width: 120px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #191c20;
    border-radius: 20px;
    color: #a1acc5;
}

.teacher-msg-line {
    border: 2px solid rgb(51, 218, 255);
    border-right: none;
}

.teacher-student-chat-box {
    box-shadow: 4px 4px 2px rgba(51, 218, 255, 1), -4px -4px 2px rgb(51, 218, 255, 1);
    height: 550px;
    top: -20px;
}

.teacher-student-chat-box>div:nth-child(4) {
    border: 1px solid #4e5966;
    width: 90%;
    margin: auto;
    margin-top: 15px;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    background-color: #364252;
    color: #a1acc5;
}







/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {
    .chat-msg-line-numbers {
        margin-bottom: 8px;
    }
}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 1200px),
(max-width: 768px),
(max-width: 576px) {


    .day-box {
        font-size: 0.8rem;
        margin-top: 5px;
    }

    .chat-line-pointer {
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 30px solid #33d6fa;
        position: absolute;
        display: block;
        z-index: 999;
        top: 285px;
        left: 15px;
    }

    .teacher-student-chat-box {
        height: 350px;
        top: -50px;
        /* margin: 0; */
        padding: 0;
    }

    .teacher-student-chat-box>div:nth-child(4) {
        font-size: 0.8rem;
    }

    .teacher-msg-line {
        width: 400px;
    }

    .chat-msg-line-numbers {
        margin-bottom: 15px;
    }

    .chat-hint-line {
        /* top: 350px; */
        top: 310px;
    }

}

@media (max-width: 768px) {
    .teacher-student-chat-box {
        /* height: 355px; */
        top: -70px;
        /* margin: 0; */
        padding: 0;
    }

    .chat-hint-line {
        /* top: 350px; */
        top: 295px;
    }

    .chat-line-pointer {
        top: 265px;
    }
}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {

    .chat-msg-line-numbers {
        margin-bottom: 20px;
    }

    .chat-hint-line {
        /* top: 350px; */
        top: 320px;
    }

    .teacher-student-chat-box {
        height: 355px;
        top: -45px;
        /* margin: 0; */
        padding: 0;
    }

    .chat-line-pointer {
        top: 295px;
        left: 5px;
    }
}