.footer-container {
    height: 67vh;
    width: 100%;
    background-image: linear-gradient(to bottom right, #171a1f, #131b29, #0b1320, #010714);
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.footer-container-mobile {
    height: auto;
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    background-image: linear-gradient(to bottom right, #171a1f, #131b29, #0b1320, #010714);
}

.footer-links-container {
    width: 45%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
}

.footer-links-container-mobile {
    margin: auto;
    margin-top: 50px;
    /* border: 1px solid red; */
    width: 65%;
    margin-bottom: 50px;
}

.footer-links-box {
    height: 80%;
    /* background-color: rgb(255, 218, 218); */
    width: 90%;
    display: flex;
    flex-direction: column;
    /* padding: 3px 0 0 0 50px; */
    /* justify-content: space-around; */
    margin: 30px 50px;
}

.footer-logo {
    height: 30px;
    margin-bottom: 10px;
}

.contact-link {
    text-decoration: none;
    /* border: 1px solid red; */
    width: fit-content;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    margin: 0;
    padding: 0;
    text-align: left;
}

.contact-icon {
    margin-right: 5px;
}

.contact-link > p, .contact-icon {
    color: #a0abc4;
    transition: 0.2s linear;
}

.contact-link:hover > p, .contact-link:hover > .contact-icon {
    /* text-decoration: underline; */
    color:  #33DAFF;
}

.footer-links {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 50px;
    /* border: 1px solid red; */
}

.footer-links>div {
    /* width: 300px; */
    /* margin-left: 100px; */
    /* border: 1px solid red; */
    margin-right: 60px;
    /* border: 1px solid wheat; */

}

.footer-links>div>div {
    margin-top: 20px;
    /* border: 1px solid red; */
    width: 10vw;
}

.footer-links>div>div>p {
    margin-top: 15px;
    cursor: pointer;
    transition: 0.2s linear;
    /* border: 1px solid red; */
    width: fit-content;
}

.footer-links>div>div>p:hover {
    color: #33DAFF;
}

.footer-line {
    height: 0.5%;
    background-image: linear-gradient(to right, #33DAFF, #33FF6C);
    /* width: 100%; */
    border-radius: 10px;
    width: 85%;
    margin-left: 50px;

}

.footer-copyrights-box {
    height: 15%;
    /* background-color: rgb(207, 230, 255); */
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    /* border: 1px solid red; */
}

.footer-copyrights-box>div:first-child {
    padding-left: 50px;
}

.footer-icon {
    color: #a1acc5;
    margin-left: 20px;
    cursor: pointer;
    transition: 0.2s linear;
}

.linkedin:hover {
    color: #0077b5;
}

.twitter:hover {
    color: #1da1f2;
}

.footer-contact-box,
.footer-contact-box-mobile {
    width: 55%;
    height: 100%;
    /* border: 1px solid green; */
    padding: 30px 50px;
}

.footer-contact-box-mobile {
    margin: auto;
    width: 100%;
}

.footer-contact-box>div:first-child,
.footer-contact-box-mobile>div:first-child {
    margin-bottom: 20px;

}

.footer-contact-box>div:nth-child(2),
.footer-contact-box-mobile>div:nth-child(2) {
    display: flex;
    /* width: 100%; */
    justify-content: space-between;
    /* border: 1px solid red; */
}

.footer-contact-box>div:nth-child(2)>div,
.footer-contact-box-mobile>div:nth-child(2)>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.contact-input,
.contact-input-large {
    height: 45px;
    width: 98%;
    padding-left: 10px;
    background-color: transparent;
    border: 2px solid rgba(161, 172, 197, 0.2);
    border-radius: 10px;
    color: #fff;
    font-size: 0.875rem;
    letter-spacing: 0.4px;
    font-family: 'Inter', sans-serif;
}

.contact-input-large {
    height: 150px;
    width: 99%;
    padding-top: 15px;
    margin-top: 20px;
    max-height: 150px;
    max-width: 100%;
    font-family: 'Inter', sans-serif;
    resize: none;
}

::placeholder {
    color: rgba(161, 172, 197, 0.6);
    /* text-align:justify; */
}

.footer-send-msg-btn {
    width: 150px;
    height: 50px;
    margin: auto;
    margin-top: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, rgb(76, 191, 237), rgb(76, 237, 160)) 1;
    cursor: pointer;
    transition: 0.3s linear;
}

.footer-send-msg-btn:hover {
    box-shadow: 4px 4px 160px rgba(76, 191, 237, 0.5), -4px -4px 160px rgba(76, 237, 160, 0.5);
    border-image: linear-gradient(to right, rgb(76, 237, 160), rgb(76, 191, 237)) 1;
}

.footer-links>div>div>p {
    color: #a0abc4;
}


/* Media Queries */

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {
    .footer-container {
        /* margin-top: 100vh; */
    }
}

/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 960px),
(max-width: 576px) {
    .footer-links-box {
        margin: 0;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* border: 1px solid yellow; */
        margin: auto;
        width: 100%;

    }


    .footer-links {
        /* border: 1px solid red; */
        display: flex;
        width: 100%;
        justify-content: space-between;
        /* margin: 0; */
    }


    .footer-links>div {
        /* border: 1px solid green; */
        margin: 0;
        /* width: 33%; */

    }

    .footer-links>div:nth-child(2)>div>p {
        white-space: nowrap;

    }

    .footer-copyrights-box {
        width: 100%;
    }

    .footer-copyrights-box>div:first-child {
        padding-left: 0;
    }

    .footer-line {
        height: 2px;
        width: 100%;
        margin: 0;
        margin-top: 50px;
        margin-bottom: -10px;
    }

}

/* Small devices (phones, 576px and up) */
@media (max-width: 576px) {
    .footer-container-mobile {
        padding: 0;
        padding-top: 50px;
        margin: 0;
        /* border: 1px solid red; */
        /* margin-top: -130px; */
    }

    .footer-contact-box-mobile>div:nth-child(2) {
        display: flex;
        flex-direction: column;
        /* border: 1px solid red; */
        width: 100%;
    }

    .contact-input {
        margin-bottom: 20px;
        width: 100%;
    }

    .contact-input-large {
        margin-top: 0;
    }

    .footer-links {
        /* border: 1px solid red; */
        width: 95%;
        display: flex;
        /* flex-direction: column; */
        /* align-items: center; */
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }

    .footer-links>div {
        margin-bottom: 50px;

    }

    .footer-links-container-mobile {
        /* border: 1px solid red; */
        width: 100%;
        padding: 0 25px;
    }

    .contact-input-large {
        width: 100%;
    }

    .footer-contact-box-mobile {
        /* border: 1px solid blue; */
        padding: 0 25px;
    }

    .footer-send-msg-btn {
        margin-top: 30px;
        margin-bottom: 50px;
    }

}