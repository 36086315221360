.content-container {
    /* height: auto; */
    width: 100%;
    /* background-color: rgb(255, 187, 187); */
    /* position: relative; */
    /* padding-top: 200px;
    padding-bottom: 200px; */
    /* border: 1px solid red; */
    /* margin-bottom: 10vh; */
    background-image: linear-gradient(to bottom right, #14161d, #0f141f, #050a13, #06173e);
    /* padding-top: 50px; */
    /* border: 1px solid green; */
    margin-top: 5vh;
}

.demo-content-box>p {
    text-align: center;
    /* margin: 50px 0; */
}

.demo-content-box>p:nth-child(2) {
    margin-top: 20px;
    line-height: 30px;
}

.demo-content-box {

    width: 70%;
    /* margin: auto; */
    padding: 20vh 0 10vh 0;
    margin-inline: auto;
    /* top: 100px; */
    color: white;
    /* border: 1px solid red; */
}

@media (max-width: 1300px) {
    demo-content-box {
        padding: 17.5em 0;
    }
}


@media (max-width: 1150px) {
    .demo-content-box {
        padding-bottom: 5em;
        width: 100%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px),
(max-width: 576px) {
    .demo-content-box {
        width: 100%;
        padding: 10em 0 22.25em 0;
    }

    .demo-content-box>p:nth-child(2) {
        margin-top: 20px;
        line-height: 25px;
    }
}

@media (max-width: 576px) {
    .demo-content-box {
        padding: 10em 0 10em 0;
    }

    .demo-content-box>p:nth-child(2) {
        padding: 0 10px;
    }
}