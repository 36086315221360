.student-demo-container {
    /* height: 85vh; */
    width: 100%;
    /* border: 1px solid white; */
    display: flex;
    margin-inline: auto;
    padding: 10vh 0;
}

.icons-box {
    width: 220px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* flex-wrap: wrap; */
    /* margin-right: 20px; */
    align-items: center;
    /* justify-content: center; */
}

.code-box {
    /* border: 1px solid blue; */
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid #626262;
    border-radius: 10px;
    height: 40em;
}

.student-code-headers {
    display: flex;
    height: 50px;
    background-color: #161B22;
    width: 100%;
    /* border-left: 1px solid #626262;
    border-right: 1px solid #626262;
    border-top: 1px solid #626262;
    position: relative;
    z-index: 999; */
    margin-bottom: -0.5px;
    align-items: center;
    /* justify-content: space-around; */
    padding-left: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.student-code {
    height: 100%;
    background-color: #24292F;
    /* border-top-right-radius: 10px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    position: relative;
    /* border: 1px solid red; */
    /* width: 100%; */
}

.icon-btn {
    height: 80px;
    width: 80px;
    /* border: 1px solid red; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 20px 20px 20px;

}

.icon-title {
    margin-top: -10px;
    color: white;
}

.icon-title-highlight {
    /* margin-left: 10px; */
    /* margin-top: 0; */
}

.code-line-numbers,
.code {
    font-family: 'Source Code Pro', monospace;
    position: relative;
    margin: 20px 20px 0 30px;
}

.code-line {
    /* margin-bottom: 30px; */
}

.student-solution-box {
    /* border: 1px solid red; */
    font-family: 'Source Code Pro', monospace;
    line-height: 30.5px;

}

.line-numbers {
    margin-bottom: 11.7px;
    font-family: 'Source Code Pro', monospace;
    z-index: 99;
    position: relative;
}

.line-number-mobile {
    margin-top: 8px;
}

.student-btn-active {
    /* border: 1px solid red; */
    box-shadow: 3px 3px 80px rgb(99, 255, 99), -3px -3px 80px rgba(255, 99, 99, 1);
}

.student-icon-active {}

.line-marker {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid red; */
}

.apply-fix-box {
    border: 1px solid rgba(92, 110, 136, 0.5);
}

.hint-btn {
    position: absolute;
    bottom: 50px;
    left: 450px;
    width: 200px;
    border: 1px solid #FFE039;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: white;
    transition: 0.5s ease;
}

.hint-icon {
    margin-right: 10px;
}

.hint-line,
.hint-line-icon,
.hint-box,
.highlight-hint-line {
    height: 30px;
    left: -5px;
    width: 32vw;
    position: absolute;
    border: 2px solid #FFE039;
    top: 280px;
}

.line-numbers {
    /* margin-bottom: 11.5px; */
}

.highlight-hint-line {
    top: 290px;
    left: -100px;
    background-color: #171b1f;
    width: 100%;
    border: 1px solid rgba(255, 224, 57, 0.6);
    /* border: 1px solid #FFE039; */
    /* border: none; */
    /* top: 279px; */
    /* width: 1300px; */
    /* width: 69.1vw; */

}

.hint-line {
    border-right: none;
}

.hint-line-icon {
    color: #FFE039;
    top: 280px;
    border: none;
    display: flex;
    align-items: center;
    left: -26px;
}

.hint-box {
    height: fit-content;
    width: 420px;
    left: 28vw;
    z-index: 9999;
    padding: 20px 0 20px 20px;
    top: 190px;
    overflow: hidden;
    border: none;
    font-family: 'Inter', sans-serif;
    box-shadow: 4px 4px 2px rgba(255, 224, 57, 1), -4px -4px 2px rgb(255, 224, 57, 1);
    letter-spacing: 0.8px;
    background-color: #161b22;

}

.hint-msg-header {
    margin-bottom: 15px;

}

.hint-msg-line {
    margin-bottom: 5px;
}

.hint-msg {
    color: blue;
}

.poblem-stmt-pointer,
.code-pointer {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -380px;
    font-family: 'Inter', sans-serif;
    color: #6a717c;

}

.poblem-stmt-pointer p,
.code-pointer p {
    color: #959aa1;
}

.code-pointer {
    top: 230px;
    right: -150px;
    color: #959aa1;
}

.icon-space {
    margin-right: 20px;
}

.icon {
    cursor: pointer;
}

.loading-box {
    /* border: 1px solid red; */
    position: absolute;
    height: 80px;
    width: 200px;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #161B22;
    border-radius: 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    z-index: 99999;
}

.loading-box>div {
    position: relative;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.failed-box {
    /* border: 1px solid red; */
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 300px;
    height: 120px;
    bottom: 20px;
    border-radius: 10px;
    background-color: #161b22;
    border: 1px solid #626262;
    z-index: 99;
}

.failed-box>div {
    display: flex;
    align-items: center;
}

.failed-msg-box {
    margin: 15px;
    margin-bottom: 20px;
}

.failed-msg-box>p {}

.show-hint-box {
    height: 50px;
    width: 160px;
    border: 1px solid rgb(255, 230, 0);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;
}

.show-hint-box>p,
.failed-msg-box>p {
    margin-left: 10px;
}

.show-hint-box:hover {
    box-shadow: 2px 2px 60px rgba(255, 252, 100, 0.3);
}

.show-hint-box:hover .bulb-icon {
    color: rgb(255, 230, 0);
}

.apply-fix-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0c0e13;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    padding: 15px 20px;
    border-radius: 10px;
    /* color: #33daff; */
    cursor: pointer;
    transition: 0.3s ease;
}

.apply-fix-box:hover {
    box-shadow: 2px 2px 60px rgba(51, 218, 255, 0.5);
    color: #6be4ff;
}

.apply-fix-box>p {
    margin-left: 10px;
}

.student-fix-clicked {
    box-shadow: 3px 3px 20px rgba(51, 218, 255, 0.9), -3px -3px 20px rgba(51, 218, 255, 0.9);
    border: 2px solid rgb(51, 218, 255, 0.9);
    color: #6be4ff;
}

.student-hint-clicked {
    box-shadow: 3px 3px 20px rgba(255, 252, 51, 0.5), -3px -3px 20px rgba(255, 252, 51, 0.5);
    border: 2px solid rgb(255, 252, 51, 0.7);
    color: #fffc33
}

.hint-line-pointer {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 30px solid #e7cd3a;
    position: absolute;
    display: none;
    z-index: 999;
    /* transition: 1s; */
}

.line-number-mobile {
    margin-bottom: 10.5px;
}

.std-demo-3-linespace {
    margin-bottom: 12.5px;
}


/* Media Queries */

@media (max-width: 1300px) {

    .hint-line,
    .hint-line-icon {
        top: 285px;
    }
}

/* Extra large devices (desktops, 1200px and up) */
@media (max-width: 1200px) {

    .student-demo-container {
        /* display: flex;
        flex-direction: column; */
        /* height: 90vh; */
        margin-inline: auto;
    }

    .hint-box {
        top: 75px;
        left: 50px;
    }

    .hint-line {
        border: 1px solid rgba(255, 224, 57, 0.5);
        border-right: 1px solid rgba(255, 224, 57, 0.5);
        background-color: #1a1e23;
        width: 400px;
    }

    .hint-line-pointer {
        position: absolute;
        display: block;
        top: 260px;
        left: 35px;
        transform: rotate(45deg);
        /* transition: 1s; */
    }

    .loading-box {
        left: 50%;
        transform: translate(-50%);
    }
}



/* Large devices (laptop, 992px and up) */
@media (max-width: 992px) {
    .student-demo-container {
        display: flex;
        flex-direction: column;
        /* height: 80vh; */
        margin-inline: auto;
    }

    .icons-box {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* border: 1px solid red; */
        /* flex-wrap: wrap; */
        margin-bottom: 20px;
    }

    .icons-box>p {}

    .hint-line-icon {
        display: flex;
        align-items: center;
    }

    .hint-box {
        /* height: 100px; */
    }

}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {

    .student-demo-container {
        margin: 0;
        padding: 0;
        /* height: 150vh; */
        margin-inline: auto;
        width: 100%;
    }

    .student-code {
        /* line-height: 15px; */
    }

    .hint-box {
        top: 55px;
        left: 40px;
        width: 370px;
        height: 205px;
    }

    .hint-line {
        /* display: none; */
        top: 273px;
        width: 420px;
    }

    .hint-line-pointer {
        top: 248px;
        left: 22px;
    }

    .icons-box {
        /* border: 1px solid red; */
        display: flex;
        margin-bottom: 40px;
        font-size: 0.8rem;

    }

    .icon-btn {
        height: 60px;
        width: 60px;
        /* margin-left: 30px; */
    }

    .hint-line-icon {
        top: 275px;
    }

    .line-numbers {
        margin-bottom: 12px;
    }

    .line-number-mobile {
        margin-bottom: 11.5px;
    }

    .icons-box>div {
        /* border: 1px solid green; */
        /* margin: 0; */
    }

    .loading-box {
        left: 50%;
        bottom: 30px;
    }

    .highlight-hint-line {
        top: 275px;
    }

    .icon-title {
        color: white;
    }

}

/* Small devices (phones, 576px and up) */
@media (max-width: 706px),
(max-width: 576px) {

    .student-demo-container {
        margin: 0;
        padding: 0;
        /* height: 95vh; */
        margin-inline: auto;
    }

    .code p {
        font-size: 0.8rem;
    }

    .student-solution-box {
        font-size: 0.8rem;
    }

    .icon {
        margin: 0;
    }

    .icons-box {
        /* border: 1px solid white; */
        display: flex;
        /* justify-content: space-around; */
        width: 100%;
        /* margin: auto; */
        /* justify-content: space-between; */

    }

    .line-marker>p {
        /* margin-left: -30px; */
        font-size: 0.6rem;
        /* margin-left: -10px; */
        /* margin-right: 70px; */
    }


    .icon-btn {
        /* border: 1px solid red; */
        /* margin: 10px 0; */
        padding: 0;
    }

    .hint-line {
        top: 265px;
        width: 350px;
    }

    .hint-box {
        top: 68px;
        left: 38px;
        width: 300px;
        height: 195px;
    }

    .hint-box p {
        font-size: 0.8rem;
    }

    .line-numbers {
        margin-left: -5px;
        margin-bottom: 15px;
    }

    .line-number-mobile {
        margin-bottom: 11px;
    }

    .hint-line {
        top: 275px;
    }

    .hint-line-pointer {
        top: 248px;
    }

    .hint-line-icon {
        top: 275px;
    }

    .failed-box {
        bottom: 10px;
    }

    .loading-box {
        bottom: 20px;
        left: 50%;
        transform: translate(-50%);
    }

    .line-numbers-space {
        /* margin-bottom: 29.5px; */
    }

    .highlight-hint-line {
        top: 280px;
        width: 320px;
    }

    .std-demo-3-linespace {
        margin-bottom: 12.5px;
    }
}


@media (max-width: 576px) {
    .line-number-mobile {
        margin-bottom: 11px;
    }

}


@media (max-width: 420px) {

    .line-number-mobile {
        margin-bottom: 13px;
    }

    .hint-box {
        top: 97px;
    }

    .hint-line-pointer {
        top: 265px;
    }

    .hint-line-icon {
        top: 290px;
    }

    .hint-line {
        top: 290px;
    }

    .std-demo-3-linespace {
        margin-bottom: 14.5px;
    }

    .highlight-hint-line {
        top: 297px;
        width: 320px;
    }
}